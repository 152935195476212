import { ChecklistItemKey } from '../../models/checkListItemModels'

/* eslint-disable no-template-curly-in-string */
export default {
  checklistTitle: 'Get ready to run your first payroll',
  checklistSubTitle: 'Finish your payroll setup by {{payrollDate}}',
  businessSetup: 'Complete business setup',
  welcome: {
    imgAlt: 'A stylized smiling team of people',
    title: 'Get ready to pay your team!',
    firstCheck:
      'Do you still plan on paying your team by <0>{{date}}</0>? Let us know if you want to change your first check date.',
    previousProvider: {
      question: 'Has your business paid your team in {{year}}?',
      questionMulti: 'Have any of your businesses paid your team in {{year}}?',
      tooltip:
        'Let us know if your business paid employees in the year you started using Toast Payroll.',
      yes: 'Yes',
      no: 'No'
    },
    businessInfo: {
      businesses: '{{count}} businesses',
      restaurants: '{{count}} restaurants',
      locations: '{{count}} locations'
    },
    whatYouWillNeed: {
      title: 'What you will need',
      items: {
        legal: 'Your legal and profile info',
        deposit: 'Your direct deposit info',
        federalTax: 'Business federal tax documents',
        stateTax: 'Business state tax documents',
        onboarding: 'Team onboarding documents',
        jobs: 'Team jobs and pay'
      }
    },
    getStarted: 'Get Started'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_COMPLETE_PAYROLL_PROFILE]: {
    title: 'Complete payroll profile'
  },
  [ChecklistItemKey.PAYROLL_SWITCHER_LINK_PREVIOUS_PROVIDER]: {
    title: 'Switch to Toast Payroll',
    stepTitle: 'Switch to Toast Payroll',
    description:
      'You need to send or enter info based on how you are switching to Toast Payroll:',
    previousConditions: {
      paycheck: 'Team members receiving a paycheck',
      payrolls: 'Payrolls processed in the current calendar year',
      taxes: 'Taxes filed in the current calendar year'
    },
    previousProvider: {
      title: 'Previous payroll provider',
      text: 'We can log into your previous payroll account to transfer information needed to help you get set up. Email us your:',
      reqs: {
        currentProvider: 'Current payroll provider',
        username: 'Username',
        password: 'Password',
        phone: 'Phone number for authentication'
      }
    },
    accountant: {
      title: 'Accountant or bookkeeper',
      p1: 'View all the <0>employee, pay and tax info</0> you need to send us.',
      p2: 'If you are switching to Toast at the start of the year, we only need info listed under employee information.'
    },
    learnMore: 'Learn more'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_PERSONAL_INFO]: {
    title: 'Complete your personal info'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_FEDERAL_I9]: {
    title: 'Fill out your federal I-9'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_FEDERAL_STATE_W4]: {
    title: 'Fill out your federal and state W4',
    tooltip: 'Complete your federal I-9 to complete your federal and state W4'
  },
  [ChecklistItemKey.PAYROLL_PROFILE_SELECT_PAYMENT_METHOD]: {
    title: 'Select your payment method'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_COMPLETE_TAX_SETUP]: {
    title: 'Complete tax setup'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_FEDERAL_TAX]: {
    title: 'Confirm federal tax info',
    stepTitle: 'Federal tax info',
    description: `You provided this Federal Employer ID Number earlier during the
      setup process. Is this still accurate? Contact your onboarding
      consultant to make changes if needed.`,
    name: 'Business legal name',
    error:
      'Contact your onboarding consultant to provide your missing business legal name',
    fein: 'Federal Employer ID Number (FEIN)',
    businessInfo: 'Business info',
    locations: 'Locations',
    missingFein:
      'Contact your onboarding consultant to provide your missing federal employer ID number'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_SIGN_IRS_FORM_8655]: {
    title: 'Sign IRS Form 8655'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_WITHHOLDING_TAX]: {
    title: 'Confirm state withholding tax info',
    stepTitle: 'Confirm state withholding tax info',
    description:
      'You were asked to provide state withholding tax info earlier during the setup process. You may be liable to tax penalties if you process payroll without accurate tax info.',
    number: 'State Withholding Tax Account number',
    numberMissing:
      'Contact your onboarding consultant to provide your missing state withholding account number.',
    frequency: 'Pay frequency',
    type: 'Pay frequency',
    frequencyMissing:
      'Contact your onboarding consultant to provide your missing state withholding pay frequency.',
    edit: 'Edit withholding tax info',
    state: 'State',
    stateMultiLoc: 'Business & state',
    taxAccount: 'Tax account',
    feinNumStates: '(1 state)',
    feinNumStatesPlural: '({{count}} states)'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_CONFIRM_STATE_UNEMPLOYMENT_TAX]: {
    title: 'Confirm state unemployment tax info',
    stepTitle: 'Confirm state unemployment tax info',
    description:
      'You were asked to provide state unemployment tax info earlier during the setup process. You may be liable to tax penalties if you process payroll without accurate tax info.',
    number: 'State Unemployment Tax Account number',
    numberMissing:
      'Contact your onboarding consultant to provide your missing state unemployment account number.',
    rate: 'Unemployment tax rate',
    type: 'Unemployment tax rate',
    rateMissing:
      'Contact your onboarding consultant to provide your missing state unemployment tax rate.',
    edit: 'Edit state unemployment tax info',
    state: 'State',
    stateMultiLoc: 'Business & state',
    taxAccount: 'Tax account',
    feinNumStates: '(1 state)',
    feinNumStatesPlural: '({{count}} states)'
  },
  [ChecklistItemKey.PAYROLL_TAX_SETUP_LINK_TOAST_TO_STATE_TAX]: {
    title: 'Link Toast to state tax account',
    header: 'Link Toast to your state accounts',
    stateTaxAccess:
      'To file and deposit quarterly taxes, set up online tax accounts with your states and link Toast Payroll as your third-party administrator.',
    confirm: 'Got it!',
    confirmTimeline:
      'Confirm Toast is linked to all state tax agencies before your next quarterly filing.',
    stateWithholding: 'withholding tax account',
    unemployment: 'unemployment tax account',
    pfml: 'Paid family medical leave account',
    findState: 'Learn how to link Toast',
    guide: 'guide',
    business: 'Business & state',
    state: 'State',
    taxAccount: 'Tax account',
    feinTaxAccount: '(1 account)',
    feinTaxAccountPlural: '({{count}} accounts)'
  },
  [ChecklistItemKey.PAYROLL_PAY_SET_UP_EMPLOYEE_PAY]: {
    title: 'Set up employee pay'
  },
  [ChecklistItemKey.PAYROLL_PAY_PAYCHECK_DELIVERY_METHOD]: {
    title: 'Confirm paycheck delivery method',
    description: 'Your paycheck delivery method is set to',
    description2:
      '. Change your paycheck delivery method if you want to select a different option.',
    methods: 'Paycheck delivery methods:',
    selfPrinted:
      'I will print checks on my own using a PDF file provided by Toast',
    selfWritten: 'I will use my own checkbook to print and distribute checks',
    toastPrinted:
      'I will pay for Toast to create, print and ship checks to specified locations ($20/package)',
    confirm: 'Confirm paycheck delivery method',
    change: 'Change paycheck delivery method'
  },
  [ChecklistItemKey.PAYROLL_PAY_OFFER_TOAST_PAY_CARD]: {
    title: 'Offer Toast Pay Card and PayOut',
    stepTitle: 'Review Toast Pay Card and PayOut',
    description:
      'Review your Toast Pay Card and PayOut set up before your team onboards to Toast. Eliminate the hassle of paying tips in cash, and get rid of those paper checks!',
    imgText:
      'Two Toast PayCards stacked on top of each other in a whimsical fashion',
    disclaimer: `Toast Pay Cards are issued by Sutton Bank, Member FDIC, pursuant to license by Mastercard®. 
      Toast PayOuts are funded by a 0% line of credit issued to employers by Toast, Inc. or WebBank, 
      as set forth in the employer's Credit Agreement. Toast and WebBank each reserve the
      right to change or discontinue this program at any time.`,
    info: 'Toast Pay Card and PayOut are not available in all jurisdictions.',
    later: 'I will set it up for a future payroll',
    setup: 'Set it up for the first payroll'
  },
  [ChecklistItemKey.PAYROLL_PAY_DEDUCTIONS_TIME_OFF]: {
    title: 'Confirm earnings, deductions, and time off',
    description: `Your onboarding consultant will help you set up pay types and time off
      policies to ensure your employees are set up to get paid accurately.`,
    example: 'Examples:',
    bonuses: 'Bonuses',
    deductions: 'Meal deductions',
    garnishments: 'Garnishments',
    pto: 'Paid time off',
    leave: 'Sick leave',
    view: 'View earnings'
  },
  [ChecklistItemKey.PAYROLL_PAY_TIPS_SETTINGS]: {
    title: 'Select tips setting',
    select: 'Select tips settings',
    description:
      'Your onboarding consultant will help you set up how you want to calculate and pay out tips to your restaurant employees.',
    types: 'Types of tips:',
    pooled: {
      setup: 'Set up',
      link: 'Toast Tips Manager',
      title: 'Pooled tips',
      description: 'to create custom tip pooling policies'
    },
    normal: {
      title: 'Normal tips',
      description:
        "Each employee's tips will sync directly from POS into Payroll after they clock out"
    },
    noTips: {
      title: 'No tips',
      description: "Each employee's tips will be entered manually in Payroll"
    },
    close: 'View tips setting'
  },
  [ChecklistItemKey.PAYROLL_TEAM_ADD_YOUR_TEAM]: {
    title: 'Add your team'
  },
  [ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_DOCS]: {
    title: 'Add employee documents',
    stepTitle: 'Add employee onboarding documents',
    add: 'Add documents',
    confirm: 'I have added all documents',
    content: `You can add optional documents for new employees to review when they join your restaurant. Let us know when you are done.`,
    example: 'Examples of documents:',
    documents: {
      handbook: 'Employee handbooks',
      business: 'Business policies',
      forms: 'State specific forms'
    }
  },
  [ChecklistItemKey.PAYROLL_TEAM_EMPLOYEE_JOBS]: {
    title: 'Confirm employee jobs',
    confirm: 'Confirm jobs',
    add: 'Add jobs',
    content:
      'This is the list of jobs you provided earlier in the setup process. Is this still accurate?',
    subtitle: 'Jobs by location',
    jobs: 'jobs',
    job: 'job'
  },
  [ChecklistItemKey.PAYROLL_TEAM_SYNC_JOBS]: {
    title: 'Sync employee jobs',
    content:
      'Your onboarding consultant is reviewing the employee jobs you entered to confirm they are set up correctly for payroll processing.',
    close: 'View job mappings',
    tooltip:
      'Confirm all jobs at your business for your onboarding consultatnt to review'
  },
  [ChecklistItemKey.PAYROLL_TEAM_INVITE_TEAM]: {
    title: 'Add or review your team',
    content: `Add or review all team members you would like to pay for your first payroll by {{dueDate}}.`,
    content2: 'Make sure every team member has a:',
    requirements: {
      social: 'Social security number',
      assigned: 'Assigned job and pay',
      method: 'Payment method',
      wtl: 'Work tax location',
      link: "Review your team's payment methods"
    },
    confirm: 'Confirm team',
    add: 'Add or review your team'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_PAYROLL_SETUP]: {
    title: 'Review payroll setup'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_COMPLETE_TRAINING]: {
    title: 'Complete payroll training',
    content: `We have curated training videos to help you learn how to process payroll
      and manage your team. We recommend watching these before you run your
      first payroll.`,
    imgText: 'A sample image from a training video for processing payroll',
    learn: 'Learn how to use Toast Payroll ',
    confirm: "I've watched the training material"
  },
  [ChecklistItemKey.PAYROLL_REVIEW_MISSING_INFO]: {
    title: 'Confirm tax setup',
    header: 'Confirm tax setup',
    confirm: 'I have reviewed missing info',
    check:
      'Check that you have no critical missing business or employee information. This will help ensure accuracy when you go to pay your team for the first time.',
    review: 'Review missing info'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_CONFIRM_SETUP]: {
    title: 'Confirm payroll setup',
    review: 'Review payroll setup',
    confirm: 'Confirm payroll setup',
    summary:
      "Here's a summary of your entire payroll setup. Please review it for accuracy.",
    switcher:
      "We have uploaded payroll info from your previous payroll provider into Toast. Here's a summary of your entire payroll setup. Please review it for accuracy and come back here to confirm.",
    edit: 'If you need to make changes, edit your payroll settings or contact your onboarding consultant for help.',
    completed: {
      alt: 'A celebratory confetti burst',
      title: "You're ready to process payroll!",
      p1: "Great job! You're nearly ready to run your first payroll. Toast Payroll makes paying your employees quick and easy, so you can use your time doing what you love.",
      p2: "Your onboarding consultant is now performing a final accuracy check to ensure you're good to run your first payroll on {{dueDate}}.",
      training: 'Learn how to run payroll'
    },
    tooltip:
      'Add or review your team and missing business info to confirm payroll setup'
  },
  [ChecklistItemKey.PAYROLL_REVIEW_PREPARE_TO_RUN_PAYROLL]: {
    title: 'Toast payroll setup review',
    content: `Your onboarding consultant is reviewing all your business, tax and employee
      pay settings to pay your employees accurately.`,
    review: 'Review payroll setup',
    tooltip:
      'Confirm payroll setup for your onboarding consultant to review all payroll settings'
  },
  common: {
    complete: 'Mark complete',
    completedByOC: 'completed by your onboarding consultant',
    back: 'Back to checklist',
    done: "You're all done!",
    next: 'Next',
    gotIt: 'Got it',
    confirm: 'Confirm',
    loader: 'Loading payroll setup',
    error: 'Sorry, we are unable to load your payroll setup',
    notProvided: 'NOT PROVIDED',
    appliedFor: 'APPLIED FOR'
  }
}
